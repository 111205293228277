.awards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    &__item {
        width: 33.3%;
        padding: 0 20px;
        margin-bottom: 73px;
    
    }
    
    &__icon {
        margin-bottom: 20px
    }

    &__name {
        font-family: "Geometric Sans Serif";
        font-size: 30px;
        line-height: 39px;
        color: #000;
        height: 90px;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    &__text {
        font-family: Rubik;
        font-size: 20px;
        line-height: 150%;
        color: #000000; 
    }
}

@media(max-width: 1200px) {
    .awards {
        margin-top: 60px;

        &__icon {
            margin-bottom: 15px;

            img {
                width: 75px;
            }
        }

        &__name {
            font-size: 28px;
            line-height: 27px;
            height: 60px;
            margin-bottom: 10px;
        }

        &__text {
            font-size: 18px;
            line-height: 1.4;
        }
    }
}

@media(max-width: 992px) {
    .awards {
        margin-top: 60px;

        &__icon {
            margin-bottom: 10px;

            img {
                width: 70px;
            }
        }

        &__name {
            font-size: 22px;
            line-height: 23px;
            height: 52px;
        }

        &__text {
            font-size: 17px;
        }
    }
}


@media(max-width: 768px) {
    .awards {
        &__item {
            width: 50%;
        }
    }
}

@media(max-width: 560px) {
    .awards {
        margin-top: 45px;

        &__item {
            width: 100%;
            margin-bottom: 20px;
        }

        &__name {
            font-size: 18px;
            line-height: 1;
            height: auto;
        }

        &__text {
            font-size: 15px;
        }

        &__icon {
            display: none;
        }
    }
}