.bread {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 45px;

    &--center {
        justify-content: center;
    }

    &__item {
        font-family: Rubik;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.15em;
        margin-right: 40px;
        position: relative;
        text-decoration: none;
        transition: color 0.3s ease;
        color: #000000;

        &::before {
            content:" ";
            position: absolute;
            right: -20px;
            top: -1px;
            width: 1px;
            height: 23px;
            display: block;
            background-color: #000000;
        }
    }

    span {
        font-family: Rubik;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.15em;
        margin-right: 40px;
        position: relative;
        color: rgba(0, 0, 0, 0.5); 
    }

    &-detail {
        position: absolute;
        top: 25px;
        z-index: 5;
        margin: 0;
        text-align: center;
        left: 0;
        right: 0;
        justify-content: center;

        span {
            color: #fff;
        }

        .bread {
            &__item {
                color: #FEF132;

                &:hover {
                    color: #fff;
                }

                &::before {
                    background-color: #FEF132;
                }
            }

           
        }
    }
}