.btn {

    position: relative;
    padding: 0;
    border: 0;
    outline: 0;



    span {
        width: 100%;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.11em;
        line-height: 1;
        text-transform: uppercase;
        position: relative;
        outline: 0;
        font-family: "Geometric Sans Serif";
        color: #FFFFFF;
        border: 3px solid #FFFFFF;
        text-decoration: none;
        box-sizing: border-box;
        padding: 16px 36px;
        display: inline-block;
        z-index: 2;
        transition: background-color 0.3s, color 0.3s ease
    }

    &:hover {
        span {
            background-color: #fff;
            color: @blue;
        }
    }

    &-wrap {
        margin-top: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--black {
        span {
            color: #000;
            border: 3px solid #000; 
        }

        &:hover {
            span {
                color: #fff;
                background-color: #000;
            }
  
        }
    }

    &--dark {
        &::before {
            content: " ";
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(~"100% + 6px");
            height: calc(~"100% + 6px");
            background: #000;
            opacity: .1;
            z-index: 1;
        }

        span {
            color: #fff;
            border: 3px solid #000;
            background-color: #000; 
        }

        &:hover {
            span {
                background-color: #000; 
                color: #FEF132; 
            }
        }
 
    }

    &--submit {


        span {
            height: 56px;
            padding: 17px 36px;
        }
    }

    &--white {
        span {
            background-color: #fff;
            color: @pumber;

            &:hover {
                background-color: @pumber;
                color: #fff;
            }
        }

        &::before {
            content: " ";
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(~"100% + 6px");
            height: calc(~"100% + 6px");
            background: #000;
            opacity: .1;
            z-index: 1;
        }
    }

    &--yellow {
        span {
            border: 0;
            background-color: #FEF132;
            color: #000000;

            &:hover {
                color: #FEF132;
                background-color: #000000; 
            }
        }

        &::before {
            content: " ";
            position: absolute;
            top: 7px;
            left: 7px;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .1;
            z-index: 1;
        }
    }

    &--small {
        span {
            padding: 12px 37px;
        }
    }

    &--big {
        span {
            padding: 18px 40px;
            font-size: 20px;
            line-height: 28px;
        }
    }
}

@media(max-width: 1200px) {
    .btn-wrap {
        margin-top: 75px;
    }
}

@media(max-width: 992px) {
    .btn-wrap {
        margin-top: 50px;
    }
}


@media(max-width: 768px) {
    .btn-wrap {
        margin-top: 35px;
    }
}


@media(max-width: 560px) {
    .btn {

        span {
            font-size: 14px;
            padding: 14px 25px;
        }

        &--submit {
            span {
                height: auto;
            }
        }

        &--big {
            span {
                padding: 15px 29px;
                font-size: 16px;
                line-height: 17px;
            }
        }
    }
}