.history {
    &__item {
        margin-bottom: 27px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
    }

    &__number {
        font-family: "Geometric Sans Serif";
        font-size: 72px;
        line-height: 89px;
        letter-spacing: .05em;
        color: #fef132;
        margin-bottom: -27px;
    }
}

@media(max-width:1200px) {
    .history {
        &__number {
            font-size: 63px;
            line-height: 77px;
        }

        &__text {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

@media(max-width:992px) {
    .history {
        &__number {
            font-size: 57px;
            line-height: 74px;
        }

        &__item {
            margin-bottom: 10px;
        }

        &__text {
            font-size: 16px;
            line-height: 22px;
        }
    }
}
