* {
    box-sizing: border-box;
}


@font-face {
	font-family: 'Geometric Sans Serif';
	src: url('../fonts/geometricsansserifv1.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


section {
    overflow: hidden;
}

p {
    margin: 0;
    margin-bottom: 10px;
    font-family: Rubik;
    font-size: 20px;
    line-height: 30px;

}


h2 {
    margin: 0;
    font-family: 'Geometric Sans Serif';
    font-size: 56px;
    line-height: 69px;
    letter-spacing: 0.05em;
    color: #000000;
    position: relative;
    text-align: left;
    margin-bottom: 30px;
}

h3 {
    font-family: 'Geometric Sans Serif';
    font-size: 36px;
    line-height: 40px;
    margin: 0;
    margin-bottom: 30px;  
}

body {
    position: relative;
    padding-top: 150px;
}



@media (max-width: 1700px) {
    body {
        padding-top: 137px;
    }
}

@media (max-width: 1450px) {
    body {
        padding-top: 122px;
    }
}

@media (max-width: 1200px) {
    body {
        padding-top: 50px;
    }

    h3 {
        font-size: 34px;
        line-height: 38px;
        margin-bottom: 28px;
    }

    h2 {
        letter-spacing: 0.05em;
        font-size: 45px;
        line-height: 45px;
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    h2 {
        letter-spacing: 0.05em;
        font-size: 35px;
        margin-bottom: 15px;
        line-height: 1.2;
    }


}

@media (max-width: 560px) {
    h2 {
        letter-spacing: 0.05em;
        font-size: 30px;
        margin-bottom: 15px;
        line-height: 1.2;
    }
}




@media (max-width: 360px) {
    body {
        padding-top: 70px;
    }

    p {
        font-size: 17px;
        line-height: 24px;
    }
}

.body-innerwrapper {
    position: relative;
}

.section {
    padding: 110px 0 100px;

    h2 {
        font-family: 'Geometric Sans Serif';
        font-size: 56px;
        line-height: 69px;
        letter-spacing: 0.05em;
        color: #000000;
        position: relative;
        text-align: center;
        margin-bottom: 102px;
    }
}

@media(max-width: 1200px) {
    
    .section {
        padding: 60px 0;

        h2 {
            letter-spacing: 0.05em;
            font-size: 45px;
            line-height: 45px;
            margin-bottom: 36px;
        }
    }
}

@media(max-width: 768px) {
    
    .section {
        padding: 50px 0;

        h2 {
            letter-spacing: 0.05em;
            font-size: 35px;
            margin-bottom: 20px;
            line-height: 1.2;
        }
    }
}


@media(max-width: 560px) {
    
    .section {
        padding: 40px 0;

        h2 {
            letter-spacing: 0.05em;
            font-size: 30px;
            margin-bottom: 15px;
            line-height: 1.2;
        }
    }
}

.section--catalog-level {
    padding-top: 45px;
    position: relative;
    overflow: inherit;
    padding-bottom: 0;
    margin-bottom: -40px;

    &::before {
        content: "";
        width: 100%;
        height: 285px;
        position: absolute;
        top: -86px;
        left: 0;
        background: #f3f3f3;
    }
}

.section--text{
    padding: 152px 0 159px;

    h2 {
        margin-bottom: 58px;
    }
    
    p {
        width: 88%;
        text-align: center;
        margin: 0 auto 10px;
    }


}

@media(max-width: 1200px) {
    .section--text {
        padding: 80px 0;

        h2 {
            margin-bottom: 45px;
        }
    }
}

@media(max-width: 992px) {
    .section--text {
        padding: 70px 0;

        h2 {
            margin-bottom: 35px;
        }
    }
}

@media(max-width: 768px) {
    .section--text {
        padding: 40px 0 30px;

        h2 {
            margin-bottom: 20px;
        }
    }
}

.section--info {
    position: relative;
    background: #F3F3F3;
    padding: 149px 0 100px;
    height: 656px;

    h2 {
        text-align: left;
        margin-bottom: 70px;

       span {
           position: relative;
           z-index: 2;

           &::after {
            content: " ";
            position: absolute;
            bottom: -4px;
            left: -8px;
            right: 0;
            width: calc(~"100% + 10px");
            height: 15px;
            background: #E9008C;
            z-index: -1;
           }
       }
    }

    .btn {
        margin-top: 25px;
        display: inline-flex;
        color: #000;
        text-decoration: none;
        margin-left: 45px;

        span {
            color: #000;
        }
    }

    .text-info {
        font-family: Rubik;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #000;
        padding-left: 45px;
    }

    
    .gallery {
        position: absolute;
        left: 0;
        top: 0;
        height: 656px;
        width: calc(~"100vw - 640px - ((100vw - 1280px + 200px) / 2)");

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(../images/common/pic.png);
            width: 274px;
            height: 100%;
            pointer-events: none;
            z-index: 5;
            opacity: .5;
        }

        .slick-prev {
            right: 71px;
        }

        .slider-arrow {
            cursor: pointer;
            top: 156px;
            width: 70px;
            height: 70px;
        }

        &__item {
            height: 656px;
            width: 100%;

        }
    }

    &-two {
        height: 840px;

        .btn {
            margin-left: 0;
        }

        .gallery {
            right: 0;
            left: initial;
            height: 840px;

            .slick-prev {
                left: 0;
                right: initial;
            }

            .slick-next {
                left: 71px;
                right: initial;
            }

            &__item {
                height: 840px;
            }

            &::before {
                right: 0;
                left: inherit;
            }
        } 

        .text-info {
            padding-left: 0;
        }
    }
}

@media(max-width: 1200px) {
    .section--info {
        padding: 80px 0;
        height: 513px;

        
        .gallery {
            overflow: hidden;
            height: 513px;
            width: calc(~"100vw - 640px - ((100vw - 1594px + 200px) / 2)");

            &__item {
                height: 513px;
            }
        }

        h2 {
            margin-bottom: 28px;
        }

        .btn {
            margin-left: 0;
        }

        .text-info {
            font-size: 18px;
            line-height: 27px;
            padding-left: 0; 
        }

        &-two {
            height: auto;

            .gallery {
                height: 100%;

                .slick-list {
                    height: 100%;

                    .slick-track {
                        height: 100%;
                    }
                }

                &__item {
                    height: 100%;
                }
            }
        }
    }
}

@media(max-width: 992px) {
    .section--info {
        padding: 0;
        height: auto;

        .col-xs-12 {
            padding: 50px 15px;
        }

        .gallery {
            position: relative;
            top: inherit;
            left: inherit;
            width: 100%;
            height: 440px;

            &__item {
                height: 440px;
            }
        }
    }
}


@media(max-width: 768px) {
    .section--info {
        .text-info {
            font-size: 16px;
            line-height: 20px;
        }

        .btn {
            margin-top: 21px;
        }

        .gallery {
            height: 350px;

            &__item {
                height: 350px;
            }

            .slick-prev {
                left: 0;
            }

            .slick-next {
                right: 0;
            }

            .slider-arrow {
                cursor: pointer;
                top: 50%;
                transform: translateY(-50%);
                width: 55px;
                height: 55px;
            }

            &::before {
                display: none;
            }
        }

        h2 {
            span {
                &:after {
                    content: " ";
                    bottom: -5px;
                    left: -3px;
                    width: calc(~"100% + 2px");
                    height: 12px; 
                }
            }
        }

        &-two {
            .gallery {
                .slick-next {
                    left: 56px;
                }
            }
        }
    }
}

.section--catalog {
    background: #F3F3F3;

    h2 {
        span {
            position: relative;
            z-index: 2;

            &::after {
                content: " ";
                position: absolute;
                bottom: -15px;
                left: 0;
                background-image: url(../images/common/line-catalog.svg);
                display: block;
                width: 100%;
                height: 25px;
                z-index: -1;
            }
        }
    }
}



@media(max-width: 560px) {
    .section--catalog {
        h2 {
            span {
                &::after {
                    bottom: -11px;
                    height: 15px;
                }
            }
        }
    }
}


.section-awards {
    background-color: @yellow;

    h2 {
        span {
            position: relative;
            z-index: 2;

            &::after {
                content: " ";
                position: absolute;
                bottom: -15px;
                left: 0;
                background-image: url(../images/title.svg);
                display: block;
                width: 100%;
                height: 25px;
                z-index: -1;
            }
        }
    }
}

@media(max-width: 768px) {
    .section-awards {
        h2 {
            span {
                &::after {
                    bottom: -19px;
                }
            }
        }
    }
}

@media(max-width: 560px) {
    .section-awards {
        h2 {
            span {
                &::after {
                    bottom: -11px;
                    height: 15px;
                }
            }
        }
    }
}

.section-number {
    padding: 159px 0 145px;
    background-color: @pumber;
}

@media(max-width: 1200px) {
    .section-number {
        padding: 100px 0;
    }
}

@media(max-width: 768px) {
    .section-number {
        padding: 50px 0;
    }
}

.section-stock {
    padding: 131px 0 130px;

    h2 {
        letter-spacing: 0.05em;
        font-size: 56px;
        margin-bottom: 36px;

        span {
            position: relative;

            &::before {
                content: " ";
                position: absolute;
                bottom: -3px;
                left: -8px;
                right: 0;
                width: calc(~"100% + 10px");
                height: 15px;
                background: #fef132;
                z-index: -1;
            }
        }
    }
}

@media(max-width: 1200px) {
    
    .section-stock {
        padding: 60px 0;

        h2 {
            letter-spacing: 0.05em;
            font-size: 45px;
            margin-bottom: 36px;

        }
    }
}

@media(max-width: 768px) {
    
    .section-stock {
        padding: 50px 0;

        h2 {
            letter-spacing: 0.05em;
            font-size: 35px;
            margin-bottom: 20px;
            line-height: 1;

            span {
                border-bottom: 8px solid #fef132;
                line-height: 1;
                display: inline-block;

                &::before {
                    display: none;
                    height: 8px;
                }
            }
        }
    }
}

@media(max-width: 560px) {
    
    .section-stock {
        padding: 50px 0;

        h2 {
            letter-spacing: .05em;
            font-size: 30px;
            margin-bottom: 15px;
            line-height: 1.2;
        }
    }
}

.section-text {
    font-family: Rubik;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000;
    margin-bottom: 85px;

    &__line {
        padding: 0 3px;
        color: #fff;
        background-color: @pumber;

    }
}

@media(max-width: 1200px) {
    .section-text {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 50px;
    }
}

@media(max-width: 768px) {
    .section-text {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 30px;
    
    }
}

.section-news {
    position: relative;

    &::before {
        content:" ";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 483px;
        background: #1CAFED;
    }
}

.section-form {
    background: #fef132;
    padding: 137px 0 114px;;

    .form {
        width: 90%;
        margin: 0 auto;
        float: inherit;
    }

    h2 {
        margin-bottom: 26px;
    }

    .section-text {
        margin-bottom: 41px;
    }
}

@media(max-width: 1200px) {
    .section-form {
        padding: 100px 0;

        .form {
            width: 100%;
        }
    }
}
@media(max-width: 992px) {
    .section-form {
        padding: 60px 0;

        .section-text {
            margin-bottom: 28px;
        }
    }
}


@media(max-width: 560px) {
    .section-form {
        padding: 50px 0;

        .section-text {
            margin-bottom: 15px;
        }
    }
}


.section-gallery {
    position: relative;
    background-color: @pumber;

}




.container {
    width: 100%;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }  
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media (min-width: 1310px) {
    .container {
        width: 1280px;
    }
}




.svg-icon {
    use {
        transition: fill 0.3s ease;
    }
}

.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical>.btn-group:before, .btn-group-vertical>.btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after {
    content: " ";
    display: table;
}

.clearfix:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical>.btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-footer:after {
    clear: both;
}


.slider-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 80px;
    height: 80px;
    background-color: #fff;
    position: absolute;
    z-index: 5;

    &:hover {
        .svg-icon {
            use {
                fill: @pumber;
            }
        }
    }
}



@media(max-width: 1200px) {
    .slider-arrow {
        width: 70px;
        height: 70px;
    }
}

@media(max-width: 560px) {
    .slider-arrow {
        width: 50px;
        height: 50px;

        .svg-icon {
            width: 12px;
        }
    }
}


.slick-dots {
    display: flex;

    li {
        list-style: none;
        margin: 0 9px;

        &.slick-active {
            button {
                background-color: #fff;
            }
        }

        &:hover {
            button {
                background-color: #fff;
            }
        }

        button {
            width: 8.83px;
            height: 8.83px;
            cursor: pointer;
            outline: 0;
            border: 0;
            font-size: 0;
            background-color: transparent;
            padding: 0;
            margin: 0;
            background-color: #68696c;
            transform: rotate(-45deg);
            transition: background-color 0.3s ease;
        }
    }
}

.jq-selectbox__select {
    display: flex;
    align-items: center;
    height: 70px;
    background: #fff !important;
    font-family: Rubik;
    font-size: 28px;
    line-height: 56px;
    color: #000000;
    padding-left: 28px;
    width: 100%;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    border: 0!important;
    transition: background 0.3s ease;
}

.jq-selectbox__select-text {
    width: 100% !important;
    font-family: Rubik;
    font-size: 28px;
    line-height: 56px;
    color: #000000;
}

.jq-selectbox__trigger {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-image: url(../images/common/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s ease;
}

.jq-selectbox {
    width: 100%;
    border: 0;
    border-radius: 0;
    position: relative;

    &::after {
        content: " ";
        position: absolute;
        top: 5px;
        left: 5px;
        width: calc(~"100% + 6px");
        height: calc(~"100% - 5px");
        background: #000;
        opacity: 0;
        z-index: -1;
    }

    &.opened {
        .jq-selectbox__select {
            background: #F3F3F3 !important;
        }

        .jq-selectbox__trigger {
            transform: rotate(180deg);
        }

        &::after {
            opacity: 0.1;
        }

        .jq-selectbox__dropdown {
            &::after {
                opacity: 0.1;
            }
        }
    }

    li {
        padding: 0 27px 0;
        transition: background-color 0.3s, color 0.3s ease;
        font-family: Rubik;
        font-size: 24px;
        line-height: 48px;
        color: #000000;

        &.selected {
            color: #E9008C;
            background-color: transparent;
        }

        &:hover {
            color: #E9008C;
            background-color: transparent;
        }
    }
}

.jq-selectbox__dropdown {
    top: 70px!important;
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;

    &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 5px;
        width: calc(~"100% + 6px");
        height: calc(~"100% + 12px");
        background: #000;
        z-index: -1;
        opacity: 0;
    }
}

.jq-selectbox__trigger-arrow {
    display: none;
}

@media(max-width: 1200px) {
    .jq-selectbox__select {
        height: 60px;
    }

    .jq-selectbox__trigger {
        width: 60px;
    }

    .jq-selectbox__dropdown {
        top: 60px!important;
    }
}


@media(max-width: 992px) {
    .jq-selectbox__select {
        height: 55px;
        padding-left: 15px;
    }

    .jq-selectbox li {
        padding: 4px 15px;
        font-size: 18px;
        line-height: 37px;
    }

    .jq-selectbox__dropdown {
        top: 55px !important;
    }

    .jq-selectbox__trigger {
        width: 55px;
        background-size: 20px;
    }

    .jq-selectbox__select-text {
        font-size: 20px;
        line-height: 1;
       
    }
}

.top-page {
    background: #F3F3F3;
    padding-top: 78px;
    margin-bottom: 86px;

    h1 {
        font-family: "Geometric Sans Serif";
        font-size: 72px;
        line-height: 89px;
        color: #000;
        margin: 0;
        margin-bottom: -18px;

        &.center {
            text-align: center;
        }

        &.blue {
            span {
                &::before {
                    background-color: #1CAFED;
                }
            }
        }

        &.white {
            span {
                &::before {
                    background-color: #fff;
                }
            }
        }

        span {
            position: relative;
            z-index: 2;

            &::before {
                content: " ";
                position: absolute;
                bottom: -7px;
                left: -8px;
                right: 0;
                width: calc(~"100% + 10px");
                height: 21px;
                background: #E9008C;
                z-index: -1;
            }
        }
    }
}


.text-color {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height:  35px;
    color: #E9008C;    
    width: 85%;
    margin-bottom: 86px;
}

@media(max-width: 1200px) {
    .top-page {

        padding-top: 40px;
        margin-bottom: 60px;

        .bread {
            margin-bottom: 18px;
        }

        h1 {
            font-size: 61px;
            line-height: 74px;
            margin-bottom: -17px;
        }
    }

    .text-color {
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        width: 100%;
        margin-bottom: 45px;
    }
}

@media(max-width: 992px) {
    .top-page {
        padding-top: 40px;
        margin-bottom: 60px;

        .bread {
            margin-bottom: 10px;
        }

        h1 {
            font-size: 50px;
            line-height: 64px;
        }
    }

    .text-color {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 43px;
    }
}


@media(max-width: 768px) {
    .top-page {
        h1 {
            font-size: 33px;
            line-height: 55px;

            &::before {
                height: 15px;
            }
        }
    }
}

.block-page {
    img {
        max-width: 100%;
        margin: 50px 0;
    }

    ul {
        padding: 0;
        margin: 0;
        margin-bottom: 60px;

        li {
            list-style: none;
            position: relative;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            padding-left: 31px;
            margin-bottom: 10px;

            &::before {
                content:" ";
                width: 15px;
                height: 12px;
                display: block;
                position: absolute;
                top: 10px;
                left: 0;
                background-image: url(../images/common/list.svg);
            }
        }
    }

    ol {
        padding: 0;
        margin: 0;
        margin-bottom: 60px;
        counter-reset: myCounter;

        li {
            list-style: none;
            position: relative;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            padding-left: 31px;
            margin-bottom: 10px;

            &::before {
                counter-increment: myCounter;
                content:counter(myCounter);
                display: block;
                position: absolute;
                top: 1px;
                left: 0;
                font-family: "Geometric Sans Serif";
                font-size: 22px;
                line-height: 30px;
                color: #E9008C;
            }
        }
    }
}

@media(max-width: 1200px) {
    .block-page {
        ul {
            margin-bottom: 33px;
            li {
                font-size: 18px;
                line-height: 25px;

                &::before {
                    top: 6px;
                }
            }
        }

        ol {
            margin-bottom: 33px;
            li {
                font-size: 18px;
                line-height: 25px;
            }
        }
    }
}

@media(max-width: 992px) {
    .block-page {

        img {
            margin: 20px 0;
        }
        ul {
            margin-bottom: 25px;

            li {
                font-size: 16px;
                line-height: 23px;

                &::before {
                     top: 7px;
                }
            }
        }

        ol {
            margin-bottom: 25px;
            li {
                font-size: 16px;
                line-height: 23px;
            }
        }
    }
}