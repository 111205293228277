.catalog-level {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    &__item {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: calc(~"33.3% - 40px");
        margin: 0 20px;
        margin-bottom: 40px;
        position: relative;
        height: 306px;
        text-decoration: none;

        padding: 10px 10px 30px;

        &::after {
            content:" ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
            opacity: 0.7;
        }
    }

    &__name {
        transition: color .3s ease;
        font-family: Geometric Sans Serif;
        font-size: 36px;
        line-height: 40px;
        color: #fff;
        position: relative;
        z-index: 5;
          
    }
}

@media(max-width: 992px) {
    .catalog-level {
        margin: 0 -10px;
    
        &__item {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: calc(~"33.3% - 30px");
            margin: 0 10px;
            margin-bottom: 20px;
            position: relative;
            height: 250px;
            padding: 10px 10px 30px;
            text-decoration: none;
            background-position: center;
            background-size: cover;
        }

        &__name {
            font-size: 29px;
            line-height: 32px;
        }
    }
}


@media(max-width: 992px) {
    .catalog-level {
        margin: 0 -10px;
    
        &__item {

            width: calc(~"50% - 30px");
        }
    }

    .section--catalog-level {
        padding-top: 0;
    }
}

@media(max-width: 560px) {
    .catalog-level {
        margin: 0 -10px;
    
        &__item {
            height: 250px;
            width: calc(~"100% - 30px");
        }

        &__name {
            font-size: 26px;
            line-height: 28px;
        }

    }

    .section--catalog-level {
        padding-top: 0;
    }
}

.catalog {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &__title {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Geometric Sans Serif";
        font-size: 48px;
        line-height: 60px;
        letter-spacing: .05em;
        color: #fff;
        text-align: center;
        margin-bottom: 61px;
    
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            padding: 0;
            text-align: center;
            margin-bottom: 30px;

            a {
                font-family: "Geometric Sans Serif";
                font-size: 28px;
                line-height: 28px;
                text-align: center;
                color: #FFFFFF;
                text-decoration: none;
                text-align: center;
                
            }

            &::before {
                display: none;
            }
        }
    }

    &__icon {
        margin: 0 auto;
        text-align: center;
        margin-bottom: 32px;
    }

    &__block {
        width: 25%;
        min-height: 912px;
        padding: 108px 10px 10px;

        &:nth-child(1) {
            background: #1CAFED;
        }

        &:nth-child(2) {
            background: #E9008C;
        }

        &:nth-child(3) {
            background: #FEF132;

            .catalog {
                &__title {
                    color: #000000
                }
            }

            ul {
                li {    
                    a {
                        color: #000000
                    }
                }
            }
        }

        &:nth-child(4) {
            background: #000000;
        }
    }
}

@media(max-width: 1300px) {
    .catalog {
        &__block {
            padding: 35px 15px;
            min-height: inherit;
        }

        &__title {
            height: 75px;
            font-size: 36px;
            line-height: 37px;
            margin-bottom: 34px;
        }
        ul {
            li {
                a {
                    font-size: 23px;
                    line-height: 22px;
                }
            }
        }
    }
}

@media(max-width: 992px) {
    .catalog {
        flex-wrap: wrap;

        &__block {
            width: 50%;
        }
    }
}



@media(max-width: 560px) {
    .catalog {
        &__block {
            width: 100%;
            padding: 20px 15px;
        }


        ul {
            li {

                margin-bottom: 10px;

                a {
                    font-size: 18px;
                    line-height: 16px;
                }
            }
        }

        &__title {
            height: auto;
            font-size: 25px;
            line-height: 25px;
            margin-bottom: 15px;
        }

        &__icon {
            display: none;
            margin-bottom: 22px;
        }
    }
}
