.header {
    padding-left: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 25;
    position: absolute;
    top: 0;
    background-color: #fff;
    left: 0;
    width: 100%;
    transition: min-height 0.3s, max-height 0.3s, transform 0.3s ease;
    position: fixed;

    &.is-tranform {
        transform: translateY(0%);
    }

    &.is-tranform-up {
        transform: translateY(-100%);
    }

    &.is-fixed {

        .logo {
            img {
                height: 45px;
            }
        }

        .navigation__body>ul>li>a {
           min-height: 50px;
        }

        .header {
            &__info {
                &-item {
                    overflow: hidden;
    
                    &--time {
                        height: 0;
                        opacity: 0;
                    }
    
                    &--mail {
                        height: 0;
                        opacity: 0;
                    }
                }

                &-icon {
                    width: 0;
                    overflow: hidden;
                }
            }

            &__logo {
                margin-top: -25px;

                &-title {
                    opacity: 0;
                }
            }
        }
    }

    .logo {
        img {
            transition: width 0.3s, height 0.3s ease;
            display: block;
            font-size: 0;
            height: 81px;
        }
    }

    &__logo {
        transition: margin 0.3s ease;

        &-title {
            font-family: "Geometric Sans Serif";
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.01em;
            color: #000000;
            transition: opacity 0.5s ease;
        }
        
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &-item {
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-bottom: 1px;
            height: 51px;
            transition: height 0.3s, opacity 0.3s ease;

            &:last-child {
                margin-bottom: 0;
            }

            &--time {
                .header {
                    &__info {
                        &-icon {
                            background-color: #E9008C;
                        }
                    }
                }
            }

            &--mail {
                .header {
                    &__info {
                        &-icon {
                            background-color: #1CAFED;
                        }
                    }
                }
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 51px;
            height: 51px;
            background-color: #FEF132;
            margin-left: 21px;
            transition: width 0.3s ease;
        }

        &-text {
            font-family: "Geometric Sans Serif";
            font-size: 18px;
            line-height: 40px;
            text-align: right;
            letter-spacing: 0.05em;
            color: #000000;  
        }
    }
}

.hamburger {
    display: none;
}


@media (max-width: 1800px) {
    .header {
        .logo {
            img {
                height: 65px;
            }
        }

        &__logo {
            &-title {
                font-size: 15px;
                line-height: 22px;
            }
        }
    }

    &.is-fixed {
        .header {
            &__logo {
                margin-top: 20px;
            }
        }
    }
}

@media (max-width: 1700px) {
    .header {
        padding-left: 40px;

        .logo {
            img {
                height: 50px;
            }
        }

        &__info {
            &-item {
                height: 45px;
            }
            
            &-icon {
                width: 45px;
                height: 45px;
                margin-left: 15px;
            }

            &-text {
                font-size: 17px;
                line-height: 1.2;
            }
        }

        &__logo {
            &-title {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}



@media (max-width: 1500px) {
    .header {
        padding-left: 30px;

        &__logo {
            &-title {
                font-size: 13px;
                line-height: 20px;
            }
        }

        &__info {

            &-item {
                height: 40px;
            }

            &-icon {
                width: 40px;
                height: 40px;
                margin-left: 10px;
            }

            &-text {
                font-size: 15px;
            }
        }

        &.is-fixed {
            .logo {
                img {
                    height: 40px;
                }
            }
        }

        .logo {
            img {
                height: 40px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .header {
        height: 60px;
        padding-left: 15px;
        justify-content: flex-end;

        &.is-fixed {
            .logo img {
                height: 33px;
            }

            .header {
                &__logo {
                    margin-top: 0;

                    &-title {
                        opacity: 1;
                    }
                }
            }
     
        }

        .logo {
            img {
                height: 33px;
            }
        }

        &__logo {
            position: absolute;
            left: 15px;
            top: 4px;

            &-title {
                font-size: 12px;
                line-height: 17px;
            }
        }

        &__info {
            &-item {

                &--time {
                    display: none;
                }

                &--mail {
                    display: none;
                }

                
            }

            &-icon {
                display: none;
            }
        }
    }

    .hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        flex-direction: column;
        width: 60px;
        height: 60px;
        background-color: @pumber;

        span {
            width: 60%;
            height: 4px;
            background-color: #fff;
            border-radius: 2px;
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    
}


@media (max-width: 768px) {
    .header {
        &__info {
            &-item {
                display: none;
            }
        }
    }
}


@media (max-width: 380px) {
    .header {
        height: 70px;

        &.is-fixed {
            .header { 
                

                &__logo {
                    max-width: 166px;
                    margin-top: 0;
        
                    &-title {
                       opacity: 1;
                    }
                }
            }

        }

        &__logo {
            max-width: 166px;

            &-title {
                font-size: 11px;
                line-height: 12px;
                margin-bottom: 3px;
            }
        }

        &__info {
            &-item {
                display: none;
            }
        }
    }

    .hamburger {
        width: 70px;
        height: 70px;

        span {
            width: 65%;
            height: 6px;
            background-color: #fff;
            border-radius: 2px;
            margin-bottom: 5px;
        }
    }
}