.number {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: calc(~"33.3% - 30px");
        margin: 0 15px;
        text-align: center;
    }

    &__text {
        font-family: "Geometric Sans Serif";
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
    }

    &__value {
        font-family: "Geometric Sans Serif";
        font-size: 144px;
        line-height: 127px;
        position: relative;
        margin-bottom: 52px;
        color: #fff;

        &::before {
            content: " ";
            position: absolute;
            bottom: -24px;
            height: 37px;
            width: 270px;
            display: block;
            background-color: #1cafed;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
}

@media(max-width: 1200px) {
    .number {
        &__value {
            font-size: 90px;
            line-height: 1;
            margin-bottom: 20px;

            &::before {
                bottom: -13px;
                height: 22px;
                width: 100%;
            }
        }

        &__text {
            font-size: 24px;
            line-height: 27px; 
        }
    }
}


@media(max-width: 768px) {
    .number {
        margin: 0;

        &__item {
            width: 100%;
            margin: 0;
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__value {
            font-size: 85px;
            margin-bottom: 10px;

            &::before {
                bottom: -4px;
                height: 13px;
                width: 198px;
            }
        }

        &__text {
            font-size: 24px;
            line-height: 27px; 
        }
   
    }
}

@media(max-width: 560px) {
    .number {

        &__item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__value {
            font-size: 75px;
            margin-bottom: 7px;

            &::before {
                bottom: -3px;
                height: 9px;
                width: 180px;
            }
        }

        &__text {
            font-size: 22px;
            line-height: 22px;
        }
    }
}