.footer {
    background-color: #fff;
    padding: 100px 0 102px;

    &__logo {
        display: inline-block;
        margin-bottom: 23px;
    }

    .copyright {
        font-family: Rubik;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 10px;
    }

    .politic {
        margin-bottom: 9px;
        display: inline-block;
        font-family: Rubik;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
            color: @blue;
        }
    }

    &__subtitle {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 11px;
    }

    .conf {
        font-family: Rubik;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        text-decoration: underline;
        transition: color 0.3s ease;
        display: inline-block;
        margin-bottom: 37px;
        
        &:hover {
            color: @blue;
        }
    }

    &__title {
        font-family: "Geometric Sans Serif";
        font-size: 20px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 38px;


        span {
            position: relative;

            &::before {
                content:" ";
                width: 180.22px;
                height: 10px;
                display: block;
                position: absolute;
                bottom: -11px;
                left: 0;
                background-image: url("./images/common/line.svg")
            }
        }
    }

    &__item {
        font-family: Rubik;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        text-decoration: none;
        margin-bottom: 10px;
        padding-right: 15px;
        
        a {
            text-decoration: none;
            font-family: Rubik;
            font-style: normal;
            font-weight: 300;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            text-decoration: none;
        }   
    }

    &__mobile {
        display: none;
    }
}


.site {
    display: inline-flex;
    flex-direction: column;

    &:hover {
        .svg-youx {
            use {
                fill: @blue;
            }
        }
    }
}

.social {
    display: flex;
    margin-bottom: 24px;


    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        margin-right: 1px;
        background-color: #A8A9AD;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #000000;
        }
    }
}

@media(max-width: 992px) {
    .footer {
        padding: 70px 0;

        &__logo {
            width: 90%;

            img {
                max-width: 100%;
            }
        }

        &__title {
            font-size: 18px;
            line-height: 17px;
            margin-bottom: 29px;
        }

        &__item {
            font-size: 14px;
            line-height: 16px;

            a {
            font-size: 14px;
            line-height: 16px;
            }
        }

        &__subtitle {
            font-size: 15px;
            line-height: 19px;
            margin-bottom: 7px;
        }

        .copyright {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 5px;
        }

        .politic {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 5px;
        }

        .conf {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 15px;
        }
    }

    .social {
        margin-bottom: 15px;

        &__item {
            width: 45px;
            height: 45px;
        }
    }
}

@media(max-width: 768px) {
    .footer {
        padding: 50px 0;

        &__logo {
            display: block;
            width: 230px;
            margin: 0 auto 0;
        }

        &__title {
            font-size: 18px;
            line-height: 17px;
            margin-bottom: 11px;
            text-align: center;
            margin-top: 30px;

            span {
  
                &::before {
                    display: none;
                }
            }
        }

        &__item {
            text-align: center;
        }

        &__block {
            text-align: center;
        }


        &__desc {
            display: none;
        }

        &__mobile {
            margin-top: 25px;
            display: block;
            text-align: center;
        }
    }

    .social {
        justify-content: center;
    }
}