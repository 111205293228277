.stock {
    display: flex;
    margin: 0 -20px;



    &__date {
        font-family: Rubik;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        margin-bottom: 8px;
    }

    &__name {
        display: flex;
        align-items: center;
        font-family: "Geometric Sans Serif";
        font-size: 23px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        height: 160px;
        position: relative;
    }

    &__btn {
        .btn {
            &::before {
                display: none;
            }
        }
    }

    &__price {
        height: 133px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        font-family: "Geometric Sans Serif";
        font-size: 44px;
        line-height: 1;
        color: #fff;
        margin-top: 5px;
        text-align: center;

        span {
            font-size: 97px;
        }

        &-more {
            font-family: Rubik;
            font-size: 18px;
            line-height: 30px;
            margin-top: 6px;
            text-align: center;
            color: #fff;
        }
    }

    &__btn {
        margin-top: 30px;

        .btn {
            width: 204px;
            margin: 0 auto;

            span {
                padding: 14px 14px;
                height: 50px;
                color: rgba(28, 175, 237, 1);
            }


        }
    }

    &__body {
        height: 500px;
        padding: 30px;
        position: relative;
        transition: margin 0.3s ease;

        &::before {
            content:" ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            background-color: rgba(28, 175, 237, 0.8);

        }
       
    }

    &__item {
        width: calc(~"33.3% - 40px");
        margin: 0 20px;
        text-decoration: none;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 18px;
            top: 33px;
            background: #000000;
            opacity: 0.1;
        }

        &:nth-child(2) {
            .stock {
                &__body {

                    &::before {
                        background-color: #E9008C;
                        opacity: 0.8;
                    }
                    
                }

                &__btn {
                    .btn {
                        span {
                            color: #E9008C;
                        }  
                    }
                }
            }
        }

        &:nth-child(3) {
            .stock {
                &__body {

                    &::before {
                        background-color: #000000;
                        opacity: 0.8;
                    }
                    
                }

                &__btn {
                    .btn {
                        span {
                            color: #000;
                        }  
                    }
                }
            }
        }

        &:hover {
            .stock {

                &__body {
                    margin-top: -15px;
                }

                &__btn {
                    .btn {
                        span {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1200px) {
    .stock {
        margin: 0 -15px;

        &__item {
            width: calc(~"33.3% - 30px");
            margin: 0 15px;   
        }

        &__body {
            padding: 20px;
            height: 430px;
        }

        &__price {
            height: 95px;
        }

        &__name {
            font-size: 21px;
            line-height: 24px;
        }

        &__price {
            span {
                font-size: 61px;
            }
        }


        &__date {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

@media(max-width: 992px) {
    .stock {
        flex-wrap: wrap;

        &__item {
            width: calc(~"100% - 30px");
            margin: 0 15px;
            margin-bottom: 45px;

            &:hover {
                .stock {
                    &__body {
                        margin: 0;
                    }
                }
            }

            &::before {
                top: 18px;
            }
            
            &:last-child {
                margin-bottom: 0;
            }
        }

        &__name {
            width: 100%;
            height: auto;
            padding: 15px 0;
            justify-content: center;
            text-align: center;
        }

        &__body {
            height: auto;
            
        }
    }
}


@media(max-width: 768px) {
    .stock {
        &__name {
            font-size: 18px;
            line-height: 22px;
        }

        &__btn {
            margin-top: 15px;
        }

        &__item {
            &::before {
                display: none;
            }
            
            margin-bottom: 30px;
        }

        &__price {
            font-size: 27px;
            margin-top: 0;
            height: auto;
            padding: 5px 0;

            span {
                font-size: 47px;
                line-height: 1;
            }
        }
    }
}