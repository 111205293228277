.main-banner {
    overflow: hidden;
    height: 971px;

    &__slider {
        float: left;
        width: calc(~"100% - 838px");
        height: 100%;

        .slick-dots {
            position: absolute;
            z-index: 2;
            bottom: 28px;
            right: 100px;
            margin: 0;
            padding: 0;
        }

        .slick-arrow {
            width: 70px;
            height: 70px;
        }

        .slick-prev {
            bottom: 0;
            left: 83px;
            padding-right: 5px;
        }

        .slick-next {
            bottom: 0;
            left: 154px;
            padding-left: 5px;        
        }
    }

    &__info {
        margin-top: 56px;
        display: flex;
        align-items: center;
        position: relative;
        font-family: "Geometric Sans Serif";
        font-size: 36px;
        line-height: 32px;
        color: #FFFFFF;
        height: 100%;

        .svg-icon {
            width: 60px;
            height: 60px;
            margin-right: 21px;
        }
    }

    &__btn {
        position: absolute;
        width: 224px;
        bottom: 0;
        left: 224px;

        &::before {
            display: none;
        }

        span {
            padding: 24px 22px;
            height: 70px;
        }

    }

    &__item {
        padding: 150px 103px 184px;
        position: relative;
        height: 971px;
        display: flex !important;
        align-items: flex-end;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.71) 0%, rgba(0, 0, 0, 0.2) 83.21%);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__title {
        position: relative;
        font-family: "Geometric Sans Serif";
        font-size: 90px;
        line-height: 90px;
        color: #FFFFFF;
    }

    &__form {
        float: left;
        height: 100%;
        width: 838px;
        padding: 90px 113px;
        background: #FEF132;

        .form {
            margin: 0;

            &__field {
                width: 100%;
            }

            &__agree {
                margin-bottom: 0;
                margin-top: 0;

                .text {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 14px;
                    line-height: 21px;
                    text-align: left;
                    padding-top: 5px;

                    a {
                        color: rgba(0, 0, 0, 0.5);
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }

            &__btn {
                width: auto;
                margin-right: 40px;

                &:hover {
                    &~.form__agree {
                        .text {
                            color: #000;

                            a {
                                color: #E9008C;
                            }
                        }
                    }
                }

                .btn {
                    width: 314px;

                    span {
                        padding: 18px 25px;
                    }
                }
            }

            &__bottom {
                display: flex;
                align-items: center;
                margin-top: 65px;
            }
        }

        &-title {
            font-family: "Geometric Sans Serif";
            font-size: 56px;
            line-height: 56px;
            color: #000000; 
            margin-bottom: 35px;
        }
    }
}

@media(max-width: 1700px) {
    .main-banner {
        &__form {
            width: 650px;
            padding: 90px 65px;
        }

        &__slider {
            width: calc(~"100% - 650px");
        }

        &__title {
            font-size: 75px;
            line-height: 75px;
        }

        &__info {
            margin-top: 50px;
            font-size: 33px;
            line-height: 30px;
        }
    }
}

@media(max-width: 1400px) {
    .main-banner {

        height: 854px;

        &__form {
            width: 550px;
            padding: 50px;

            .form {
                &__bottom {
                    margin-top: 11px;
                    flex-direction: column;
                    align-items: flex-start;
                }

                &__agree {
                    margin-top: 13px;
                }
            }

            &-title {
                font-size: 40px;
                line-height: 43px;
                color: #000;
                margin-bottom: 25px;
            }
        }

        &__item {
            height: 854px;
            padding: 150px 80px;
        }

        &__slider {
            width: calc(~"100% - 550px");
        }

        &__title {
            font-size: 65px;
            line-height: 65px;
        }

        &__info {
            margin-top: 44px;
            font-size: 30px;
            line-height: 28px;

            .svg-icon {
                width: 40px;
                height: 40px;
                margin-right: 15px;
            }
        }
    }
}

@media(max-width: 1200px) {
    .main-banner {
        height: 700px;

        &__btn {
            
        }

        &__form {
            width: 40%;
            padding: 30px;

            .form {
                padding: 0;
     
                &__btn .btn span {
                    padding: 12px 19px;
                    font-size: 18px;
                }
            }

            &-title {
                font-size: 35px;
                line-height: 29px;
                margin-bottom: 22px;
            }

        }
        


        &__info {
            margin-top: 36px;
            font-size: 24px;
            line-height: 24px;
        
        }

        &__title {
            font-size: 47px;
            line-height: 48px;
        }

        &__item {
            height: 700px;
            padding: 50px;
            padding-bottom: 78px;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }

        &__slider {
            width: 60%;

            .slick-prev {
                left: 50px;
            }

            .slick-next {
                bottom: 0;
                left: 121px;
            }
        }

        &__btn {
            position: relative;
            left: auto;
            top: auto;
            display: block;
            margin-top: 29px;
        }
    }
}

@media(max-width: 992px) {
    .main-banner {
        height: auto;

        &__item {
            height: 450px;
        }

        &__form {
            width: 100%;
            float: none;

            .form {
                display: flex;
                width: calc(~"100% + 40px");
                margin: 10px -20px 0;

                &__bottom {
                    margin: 48px 20px 0;
                    flex-direction: column;
                    align-items: flex;
                }

                &__field {
                    width: calc(~"50% - 40px");
                    margin: 0 20px;
                    margin-bottom: 15px;
                }
            }
        }

        &__slider {
            width: 100%;
            float: none;
        }
    }
}


@media(max-width: 768px) {
    .main-banner {
        &__form {
            width: 100%;
            float: none;

            &-title {
                font-size: 33px;
                line-height: 28px;
                margin-bottom: 25px;
            }

            .form {
                display: block;
                width: calc(~"100% + 0px");
                margin: 10px 0 0;

                &__bottom {
                    margin: 30px 0px 0;
                    
                }

                &__field {
                    width: calc(~"100% - 0px");
                    margin: 0 0px;
                    margin-bottom: 15px;
                }
            }
        }

        &__title {
            font-size: 39px;
            line-height: 38px;
        }

        &__btn span {
            padding: 17px 12px;
            height: 55px;
        }

        &__slider {
            .slick-arrow {
                width: 50px;
                height: 50px;
            }

            .slick-dots {
                bottom: 24px;
                right: 30px;
            }

            .slick-next {
                left: 81px;
            }

            .slick-prev {
                left: 30px;
            }

        }


        &__item {
            padding: 30px;    
            padding-bottom: 78px;
        }
    }
}



@media(max-width: 480px) {
    .main-banner__form .form__btn {
        margin-right: 0;
        width: 100%;

        .btn {
            width: 100%;

            span {
                font-size: 16px;
            }
        }
    }

    .form--inner .form__field .ui-slider-handle {
        width: 10px;
        height: 10px;
        margin-left: -5px;
    }
}



@media(max-width: 380px) {
    .main-banner {
        &__title {
            font-size: 28px;
            line-height: 30px;
        }

        &__item {
            padding: 30px 15px;
            padding-bottom: 60px;
        }

        &__slider {
            .slick-prev {
                left: 15px;
            }

            .slick-next {
                left: 66px;
            }

            .slick-dots {
                bottom: 22px;
                right: 15px;
            }
        }

        &__form {
            padding: 30px 15px;

            &-title {
                font-size: 31px;
                line-height: 26px;
            }
        }

        &__info {
            margin-top: 36px;
            font-size: 20px;
            line-height: 24px;

            .svg-icon {
                width: 30px;
                height: 30px;
            }
        }
    }
}