.catalog-list {
    display: flex;

    &__wrap {
        max-width: 1700px;
        padding: 0 25px;
        margin: 0 auto;
    }

    &__title {
        font-family: "Geometric Sans Serif";
        font-size: 41px;
        line-height: 49px;
        color: #e9008c;
        margin-bottom: 25px;
        min-height: 98px;
        display: flex;
        align-items: center;
        margin-top: 25px;
    }

    &__block {
        width: 25%;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style: none;

            a {
                display: inline-flex;
                align-items: center;
                font-family: "Geometric Sans Serif";
                font-size: 28px;
                line-height: 28px;
                margin-left: -15px;
                padding: 15px 15px;
                color: #000000;
                text-decoration: none;
                transition: color 0.3s, background-color 0.3s ease;

                .svg-icon  {
                    margin-right: 30px; 

                    use {
                       fill: @pumber;
                    }
                }

                span {
                    opacity: 0;
                    margin-left: 10px;
                    transition: opacity 0.3s ease;
                    color: #fef132;
                }

                &:hover {
                    background-color: @pumber;
                    color: #fff;

                    .svg-icon {
                        use {
                            fill: #fff;
                        }
                    }

                    span {
                        opacity: 1;
                    }
                   
                }
            }
        }
    }
}

@media(max-width: 1400px) {
    .catalog-list {

        ul {
            li {
                a {
                    font-size: 24px;
                    line-height: 25px;

                    .svg-icon {
                        margin-right: 19px;
                    }
                }
            }
        }

        &__title {
            font-size: 37px;
            line-height: 35px;
            margin-bottom: 20px;
            min-height: 80px;
            margin-top: 20px;
        }

        &__block {
            padding-right: 10px;
        }

        &__icon {
            img {
                width: 80px;
            }
        }
    }
}


@media(max-width: 1200px) {
    .catalog-list {
        margin-top: 65px;
        flex-wrap: wrap;

        ul {
            li {
                a {
                    position: relative;
                    font-size: 21px;
                    line-height: 21px;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 40px;

                    .svg-icon {
                        position: absolute;
                        left: 16px;
                        top: 16px;
                        margin-right: 0;
                    }

                    span {
                        margin-left: 0;
                        opacity: 1;
                        color: #000;
                    }
                }
            }
        }

        &__title {
            font-size: 26px;
            line-height: 22px;
            margin-bottom: 9px;
            min-height: 44px;
            margin-top: 20px;
        }
    }
}

@media(max-width: 992px) {
    .catalog-list {
        &__wrap {
            max-width: 750px;
        }

        ul {
            li {
                a {
                    padding: 10px 15px;
                    padding-left: 33px;
                    font-size: 17px;
                    line-height: 15px;

                    &:hover {
                        color: #000;
                        background-color: transparent;
                    }

                    .svg-icon {
                        width: 9px;
                    }
                }
            }
        }

        &__title {
            font-size: 20px;
            line-height: 21px;
            margin-bottom: 9px;
            min-height: 43px;
            margin-top: 15px;
        }

        &__icon {
            img {
                width: 50px;
            }
        }

        &__block {
            width: 50%;
            margin-bottom: 35px;
        }
    }
}



@media(max-width: 768px) {
    .catalog-list {

        &__icon {
            display: none;
        }

        &__title {
            margin-top: 0;
        }

        &__block {
          //  width: 100%;
            padding-right: 0;

            ul {
                li {
                    a {
                        display: inline-block;

                        .svg-icon {
                            width: 7px;
                            top: 8px;
                        }

                        span {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

}


@media(max-width: 560px) {
    .catalog-list {
        margin-top: 45px;

        &__block {
            width: 100%;
            margin-bottom: 15px;

        }
    }
}