.gallery {
    height: 100%;

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;
        }
    }

    .slider-arrow {
        cursor: pointer;
        top: 320px;
    }

    .slick-prev {
        right: 82px;
    }

    .slick-next {
        right: 0;
    }

    &__item {
        height: 100%;
        background-position: center;
        background-size: cover;
    }
}


.slick-slide {
    outline: 0;
}

@media(max-width: 1200px) {
    .gallery {
        .slider-arrow {
            top: 55%;
        }

        .slick-prev {
            right: 72px;
        }
    }
}

@media(max-width: 560px) {
    .gallery {
        .slider-arrow {
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-prev {
            right: 52px;
        }
    }
}