.form {
    padding: 178px 0 197px;


    &__block {
        background-color: @pumber;
        overflow: hidden;
        height: 738px;

        .form__wrap {
            width: 560px;
            display: flex;
            flex-direction: column;

            form {
                text-align: right;
            }
        }
    }

    input[type='text'] {
        width: 503px;
        height: 80px;
        background-color: #ffffff;
        border: 0;
        padding: 0 25px;
        outline: 0;
    }

    &__text {
        font-family: "Geometric Sans Serif";
        font-size: 22px;
        line-height: 33px;
        text-align: right;
        color: #FFFFFF;
        margin-bottom: 46px;
    }

    &__info {
        float: left;
        width: 50%;
        height: 100%;

        
        &--slider {
            position: absolute;
            right: 0;
            top: 0;
            width: 50vw;
        }

        &--text {

            img {
                margin-bottom: 45px;
                max-width: 472px;
            }

            position: absolute;
            right: 0;
            top: 0;
            width: 50vw;
            background-color: #fff;
            padding: 135px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__agree {
        margin-top: 28px;
        margin-bottom: 15px;

        .text {
            font-family: Rubik;
            font-size: 14px;
            line-height: 20px;
            text-align: right;  
            color: rgba(255, 255, 255, 0.8);  
            transform: color 0.3s ease;

            a {
                transition: color 0.3s ease; 
                color: rgba(255, 255, 255, 0.8);  
                transform: color 0.3s ease;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    &--inner {
        padding: 0;
        width: 100%;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;

        .form {
            &__col {
                width: 50%;
                padding: 0 20px;
            }

            &__btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            &__agree {
                width: 100%;
                margin-top: 60px;
                margin-bottom: 31px;

                
                .text {
                    text-align: center;
                    color: #000000;

                    a {
                        color: #E9008C;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .form__field {
            margin-bottom: 21px;

            .ui-widget.ui-widget-content {
                border: 0;
                border-radius: 0;
                height: 7px;
                margin-top: -7px;
                background-color: #000;
            }

            .ui-slider-handle {  
                width: 20.36px;
                height: 20.36px;  
                margin-top: 15px;
                border: 0 !important;
                outline: 0;
                background: #000000;
                border-radius: 15px 15px 15px 0px;
                transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0); 
            }

            .ui-slider .ui-slider-range {
                background-color: #fff899;
                border-radius: 0;
            }

            label {
                display: block;
                font-family: Rubik;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 36px;
                color: #000000;
                opacity: 0.5; 
                transition: color 0.3s ease;
            }

            input[type=text] {
                width: 100%;
                height: 70px;
                font-size: 28px;
                line-height: 56px;
                color: #000000;
                padding: 0 28px;
                border: 3px solid transparent;
                transition: border-color 0.3s ease;

                &::-webkit-input-placeholder {color:rgba(0, 0, 0, 0.3)}
                &::-moz-placeholder          {color:rgba(0, 0, 0, 0.3)}
                &:-moz-placeholder           {color:rgba(0, 0, 0, 0.3)}
                &:-ms-input-placeholder      {color:rgba(0, 0, 0, 0.3)}
            }
        }
    }
}


@media(max-width: 1310px) {
    .form {
        &__block {
            .form {
                &__wrap {
                    width: 100%;
                }
            }
        }
    }
}

@media(max-width: 1500px) {
    .form {
        &__info {
            &--text {
                padding: 65px;

                img {
                    max-width: 100%
                }
            }
        }
    }
}

@media(max-width: 1200px) {
    .form {
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;

        .form input[type=text] {
            width: 100%;
            height: 60px;
        }

        &__agree {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        &__block {
            height: 550px;
        }

        &__info {
            &--slider {
                width: 42vw;
            }

            &--text {
                width: 42vw;
                padding: 40px;

                img {
                    margin-bottom: 28px;
                }
                
                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        &__text {
            font-size: 19px;
            line-height: 27px;
            margin-bottom: 25px;
            
        }


        &--inner {
            width: 100%;

            .form {
                &__field {
                    margin-bottom: 15px;

                    input[type=text] {
                        width: 100%;
                        height: 60px;
                        padding: 0 15px;
                    }
                }
            }
        }
    }
}


@media(max-width: 992px) {
    .form {

        input[type=text] {
            width: 100%;
            height: 60px
        }

        &__agree {
            
            .text {
                text-align: center;
            }
        }

        .btn {
            margin: 0 auto;
            display: block;
        }

        &__info {
            &--slider {
                position: relative;
                width: 100vw;
                color: black;
                height: 440px;
            }

            &--text {
                position: relative;
                width: 100vw;
                color: black;

            }
        }

        &__text {
            text-align: center;
        }

        &__block {
            height: auto;
            padding: 45px 0;

            .form {
                &__wrap {
                    align-items: center;
                }
            }
        }

        &--inner {
            flex-wrap: wrap;

            .form {
                &__col {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }

                &__agree {
                    margin-top: 33px;
                }

                &__field {
                    label {
                        font-size: 16px;
                        line-height: 27px;
                    }

                    input[type=text] {
                        height: 55px;
                        font-size: 20px;
                        line-height: 1;
                    }

                    .ui-widget.ui-widget-content {
                        margin-top: 0;
                    }
                }
            }
        }

    }
}



@media(max-width: 560px) {
    .form {

        &__agree {
            .text {
                font-size: 13px;
                line-height: 16px; 
            }
        }

        &__info {
            &--text {
                padding: 35px 15px;
                padding-bottom: 0;

                img {
                    width: 90%;
                }
            }
        }

        &__info {
            &--slider {
                height: 350px;
            }
        }

        input[type=text] {
            width: 100%;
            height: 55px
        }

        &__text {
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 21px;

            br {
                display: none;
            }
        }


        &--inner {
            .form {

                &__agree {
                    margin-top: 20px;
                    margin-bottom: 25px;
                }

                &__field {
                    label {
                        font-size: 15px;
                        line-height: 1;
                        margin-bottom: 4px;
                    }

                    input[type=text] {
                        height: 50px;
                        font-size: 18px;
                        line-height: 1;
                    }
                }
            }
        }

    }
}