.example {
    &__title {
        float: left;
        width: 33.3%;
        height: 357px;
        padding: 0 50px;
        display: flex;
        align-items: center;
        font-family: "Geometric Sans Serif";
        font-size: 56px;
        line-height: 69px;
        letter-spacing: 0.05em;
        color: #FFFFFF;    
        background-color: #E9008C;  
    }

    &__big {
        width: 75%;
        float: left;

        .example {
            &__item {
                width: 33.3%;

                &--big {
                    width: 66.7%;
                }
            }
        }
    }

    &__middle {
        width: 25%;
        float: left;

        .example {
            &__item {
                width: 100%;
            }
        }
    }

    &__item {
      //  width: 25%;
        height: 357px;
        float: left;
        background-position: center;
        background-size: cover;

        &--big {
            width: 66.7%;
            height: 714px;
        }

        &--ver {
            height: 714px;
        }
    }
}

@media(max-width: 1310px) {
    .example {
        &__item {
            height: 200px;

            &--big {
                height: 400px;
            }

            &--ver {
                height: 400px;
            }
        }

        &__title {
            padding: 0px 20px;
            height: 200px;
            font-size: 40px;
            line-height: 1.1;
        }
    }
}



@media(max-width: 992px) {
    .example {
        &__item {
            height: 150px;

            &--big {
                height: 300px;
            }

            &--ver {
                height: 300px;
            }
        }

        &__title {
            padding: 0px 15px;
            height: 150px;
            font-size: 30px;
            line-height: 1.1;
        }
    }
}


@media(max-width: 768px) {
    .example {
        &__big {
            width: 66.7%;

            .example {
                &__item {
                    width: 50%;
                }

                &__title {
                    width: 50%;
                }
            }
        }

        &__middle {
            width: 33.3%;
        }

        &__item {
            &--big {
                height: 150px;
            }
        }
    }
}



@media(max-width: 560px) {
    .example {
        &__big {
            width: 100%;
            display: flex;
            flex-direction: column;

            .example {
                &__title {
                    width: 100%;
                    font-size: 25px;
                    order: 1;
                    height: auto;
                    padding: 25px 15px;
                }

                &__item {
                    order: 2;
                }
            }
        }


        &__middle {
            width: 100%;
        }

        &__item {
            width: 100% !important;
            height: 50vw !important;
        }
    }
}