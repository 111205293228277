.navigation {

    .multiitem {
        &__control {
            display: none;
        }
    }

    &__close {
        display: none;
    }

    .header {
        &__info {
            display: none;
        }
    }

    &__body {
        &>ul {
            display: flex;
            margin: 0;
            padding: 0;
    
            &>li {
                list-style: none;
                margin-right: 45px;
                position: relative;
    
                &:last-child {
                    margin-right: 0;
                }
                
                &>a {
                    font-family: "Geometric Sans Serif";
                    font-size: 18px;
                    line-height: 18px;
                    letter-spacing: 0.04em;
                    color: #000000;  
                    text-decoration: none;   
                    min-height: 150px;
                    display: flex;
                    align-items: center;
                    transition: min-height 0.3s ease;
    
                    &>span {
                        position: relative;
                        z-index: 2;
    
                        &::after {
                            content:" ";
                            position: absolute;
                            z-index: -1;
                            width: 100%;
                            bottom: -4px;
                            left: 0;
                            transition: border-color 0.3s ease;
                            border-bottom: 8px solid transparent;
                        }
                       
                    }
    
                    &.multiitem {
                        &>span {
                            padding-right: 15px;
                            &::before {
                                content:" ";
                                position: absolute;
                                z-index: -1;
                                width: 100%;
                                bottom: -4px;
                                right: 0;
                                width: 0px;
                                z-index: 5;
                                transition: border-color .3s ease;
                                border: 4px solid transparent;
                                border-right: 4px solid rgba(0,0,0,.2);
                                border-bottom: 4px solid rgba(0,0,0,.2);
                            }
                        }
                    }
                }
    
                &:hover {
                    &>.l-dropdown {
                        opacity: 1;
                        pointer-events: auto;
                    }
    
                    &>a {
                        &>span  {
                            &::after {
                                border-bottom: 8px solid #FEF132;
                            }
                            
                            &::before {
                                border: 4px solid transparent;
                                border-right: 4px solid rgba(0,0,0,1);
                                border-bottom: 4px solid rgba(0,0,0,1);
                            }
                        }
                    }
                }
    
            }
        }
    }

}

.l-dropdown {
    margin: 0;
    padding: 0;
    width: 310px;
    position: absolute;
    z-index: 5;
    left: -20px;
    top: 100%;
    transition: opacity 0.3s ease;
    pointer-events: none;
    opacity: 0;

    &::after {
        content: " ";
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .1;
        z-index: -1;
        display: block;
        left: 18px;
        top: 18px;
        bottom: 0;
        position: absolute;
    }

    li {
        list-style: none;

        a {
            min-height: 60px;
            display: flex;
            align-items: center;
            padding: 5px 20px;
            font-family: "Geometric Sans Serif";
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.04em;
            text-decoration: none;
            color: #000000;
            transition: color 0.3s, background-color 0.3s ease;
            background-color: #FEF132;

            &.is-active {
                background-color: #000000;
                color: #FEF132;     
            }

            &:hover {
                background-color: #000000;
                color: #FEF132;
            }
        }
    }

    &__title {
        font-family: "Geometric Sans Serif";
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        text-decoration: none;

        &:hover {
            span {
                &::after {
                    opacity: 1;
                }
            }
        }

        span {
            position: relative;
            z-index: 4;

            &::after {
                content: " ";
                position: absolute;
                bottom: -6px;
                left: 0;
                z-index: -1;
                height: 10px;
                width: 100%;
                opacity: 0;
                background: #fff;
                transition: opacity .3s ease;
            }
        }
        
    }

    &__block {
        width: 326px;
        padding: 57px 0;
        background-color: #fef132;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--catalog {
        width: auto;
        display: flex;
        left: -536px;
        max-width: 1305px; 
        background-color: #FEF132;

        ul {
            padding: 0;
            width: 100%;
            margin-top: 29px;

            li {
                a {
                    justify-content: center;
                }
               
            }
        }
    }

}


@media (max-width: 1700px) {
    .navigation {
        &>ul {
            &>li {
                margin-right: 30px;

                &>a {
                    font-size: 17px;
                    line-height: 17px;
                    min-height: 137px;
                }
            }
        }
    }
}

@media (max-width: 1450px) {
    .l-dropdown {
        width: 280px;

        &__block {
            width: 250px;
            padding: 35px 0;
        }

        &__title {
            font-size: 19px;
            line-height: 19px;
        }

        li {
            a {
                min-height: 52px;
                padding: 5px 14px;
                font-size: 17px;
                line-height: 17px;
            }
        }

        &--catalog {
            width: auto;
            left: -300px;
        }
    }
}

@media (max-width: 1450px) {
    .navigation {
        &__body {
            &>ul {
                &>li {
                    margin-right: 20px;
    
                    &:hover {
                        &>a {
                            span {
                                &:before {
                                    border: 3px solid transparent;
                                    border-right: 3px solid #000;
                                    border-bottom: 3px solid #000;  
                                }
                                
                                &:after {
                                    border-bottom: 6px solid #fef132;
                                }
                            }
                        }
                    }
    
                    &>a {
                        font-size: 15px;
                        line-height: 16px;
                        min-height: 122px;
    
                        span {
                            &:after {
                                border-bottom: 6px solid transparent;
                            }
    
                            &:before {
                                border: 3px solid transparent;
                                border-right: 3px solid rgba(0,0,0,.2);
                                border-bottom: 3px solid rgba(0,0,0,.2);
                            }
                        }
                    }
                }
            }
        }

    }
}

@media(max-width: 1200px) {



    .navigation {
        position: fixed;
        z-index: 50;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.5);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &.is-open {
            opacity: 1;
            pointer-events: auto;

            .navigation {
                &__body {
                    transform: translateX(0%);
                }
            }
        }

        
        &__close {
            display: flex;
            justify-content: flex-end;
            padding: 10px 12px;
            flex: none;

            span {
                position: relative;
                display: inline-block;
                width: 25px;
                height: 25px;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    margin-top: -1px;
                    background: #000;
                    transform: rotate(45deg);
                    height: 4px;
                    margin-top: -4px;
                }
                
                &::after {
                    content: '';
                    transform: rotate(-45deg);
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    margin-top: -1px;
                    background: #000;
                    height: 4px;
                    margin-top: -4px;
                }
            }
        }

        .header {
            &__info {
                display: block;
                padding: 15px 0;
                padding-bottom: 0;

                &-item {
                    height: 30px !important;
                    opacity: 1 !important;

                    &--time {
                        display: flex;
                    }
    
                    &--mail {
                        display: flex;
                    }
    
                    
                }

                &-icon {
                    display: flex !important;
                    width: 30px !important;
                    height: 30px;
                    order: 1;
                    margin-left: 0;
                    margin-right: 15px;
                }

                &-text {
                    order: 2;
                    font-size: 14px;
                }
            }
        }

        .multiitem {
            &__control {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                position: absolute;
                right: 0;
                top: 0;
                transition: transform 0.3s ease;

                .svg-icon {
                    transform: rotate(90deg);
                    width: 10px;

                    use {
                        fill: rgba(0,0,0,.2);
                    }
                }
            }

            &.is-active {
                .multiitem {
                    &__control {
                        transform: rotate(180deg)
                    }
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            width: 350px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            transform: translateX(100%);
            transition: transform 0.3s ease;

            &>ul {
                flex-direction: column;           
                flex: 1 1 0;
                overflow-y: auto;
    
                &>li {
                    margin-right: 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

                    &>a {
                        min-height: 50px;
                        padding: 5px 15px;

                        &.multiitem {
                            span {
                                &::before {
                                    display: none;
                                }
    
                                &::after {
                                    display: none;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }

    .l-dropdown {
        opacity: 1;
        position: relative;
        left: initial;
        top: initial;
        width: 100%;
        display: none;
        pointer-events: auto;
        overflow: hidden;

        &::after {
            display: none;
        }
    
        &__title {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 50px;
            padding: 3px 3px;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 14px;
            background-color: @pumber;
            color: #fff;
            width: 100%;
        }

        li {
            a {
                min-height: 45px;
                padding: 5px 22px;
                font-size: 15px;
                line-height: 15px;  
            }
        }

        &__block {
            width: 100%;
            padding: 0%
        }

        &--catalog {
            width: 100%;
            flex-direction: column;

            ul {
                margin-top: 0;
            }
        }
    }


}


@media (max-width: 768px) {
    .navigation {
        .header {
            &__info {
                &-item {
                    display: flex;
                }
            }
        }
    }

}


@media(max-width: 400px) {
    .navigation {
        &__body {
            width: 100%;
        }
    }

}