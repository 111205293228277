.detail-info {
    padding: 129px 0 136px 0 ;

   &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 131px;

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(even) {
                

                .slider-for {
                    float: left;
                }

                .slider-nav {
                    float: left;
                    margin:  0 0 0 5px;
                }

                .detail-info {

                    &__info {
                        align-items: flex-end;
                    }

                    &__slider {
                        order: 2; 
                    }

                    &__info {
                        order: 1;
                        padding: 0 108px 0 0;
                    }
                }
            }
    }

    .slider-for {
        width: 702px;
        height: 520px; 
        float: right;

        .item {
            width: 702px;
            height: 520px; 
            background-position: center;
            background-size: cover;
        }
    }

   /* .slider-for {
        float: right;
        width: 702px;
        height: 520px; 

        .slick-list {
            width: 100% !important;
            height: 520px; 

            .slick-track {
                width: 100% !important;
                height: 520px; 
            }
        }

        .item {
            width: 702px;
            height: 520px; 
            background-position: center;
            background-size: cover;
        }
    }*/

    .slider-nav {
        float: right;
        width: 107px;
        margin-right: 5px;
        height: 520px;
        display: flex;
        align-items: center;

        .item {
            cursor: pointer;
            width: 107px;
            height: 80px;
            background-position: center;
            background-size: cover;
            transition: opacity 0.3s ease;
            opacity: 0.3;
            margin-bottom: 5px;

            &:hover {
                opacity: 1; 
            }

            &.slick-current {
                opacity: 1;
            }
        }
    }

    &__title {
        font-family: "Geometric Sans Serif";
        font-size: 36px;
        line-height: 40px;
        color: #000000;
        margin-bottom: 16px;
    }

    &__info {
        flex: 1 1 0;
        padding: 0 0 0 108px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &__tech {
        width: 296px;
        margin-bottom: 30px;

        div {
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: relative;

            &::before {
                content: " ";
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 10px;
                border-bottom: 3px dotted rgba(0,0,0,.5);
            }
        }

        span {
            background-color: #fff;
            display: inline-block;
            position: relative;
            z-index: 2;


            &:first-child {
                font-family: "Geometric Sans Serif";
                font-size: 20px;
                line-height: 36px;
                color: rgba(0, 0, 0, 0.5);
                padding-right: 3px;
            }

            &:last-child {
                font-size: 20px;
                line-height: 36px;
                text-align: right;
                padding-left: 3px;
                color: #000000;
                font-family: "Geometric Sans Serif";
            }

        }
    }

    &__slider {
        width: 815px;
        flex: none;
    }

    &__more {
        .svg-icon {
            margin-right: 8px;
        }
    }

    &__btn {
        margin-bottom: 46px;
    }

    &__price {
        font-family: "Geometric Sans Serif";
        font-size: 36px;
        line-height: 40px;
        color: #000000;
        margin-bottom: 15px;

        span {
            font-size: 78px;
        }
    }

    &__more {
        display: flex;
        align-items: center;
        font-family: "Geometric Sans Serif";
        font-size: 18px;
        line-height: 22px;
        color: #E9008C;
    }
}

@media(max-width: 1310px) {
    .detail-info {
        padding: 100px 0 100px;

        .slider-for {
            width: 580px;
            height: 400px;

            .item {
                width: 580px;
                height: 400px;
            }
        }

        .slider-nav {
            height: 400px;

            .slick-list {
                height: 400px!important;
                display: flex;
                align-items: center
            }
        }

        &__price {
            font-size: 26px;
            line-height: 1.2;

            span {
                font-size: 50px;
            }
        }

        &__tech {
            margin-bottom: 14px;

            div {
                &::before {
                    bottom: 9px;
                    border-bottom: 2px dotted rgba(0,0,0,.5);
                }
            }
            span {
                &:first-child {
                    font-size: 16px;
                    line-height: 1.2;
                    padding: 5px;
                    padding-left: 0;
                }

                &:last-child {
                    font-size: 16px;
                    line-height: 1.2;
                    padding: 5px;
                    padding-right: 0;
                }
            }
        }

        &__title {
            font-size: 29px;
            line-height: 1.2;
            margin-bottom: 10px;
        }

        &__info {
            padding: 0 0 0 25px ;
        }

        &__more {
            font-size: 16px;
            line-height: 17px;
        }

        &__btn {
            margin-bottom: 30px;
        }

        &__slider {
            width: 695px;
        }

        &__item {
            margin-bottom: 70px;

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(even) {
                .detail-info {
                    &__info {
                        padding: 0 25px  0 0;
                    }
                }
            }
        }
    }
}


@media(max-width: 1200px) {
    .detail-info {
        .slider-for {
            width: 500px;
            height: 350px;

            .item {
                width: 500px;
                height: 350px;
            }
        }

        .slider-nav {
            height: 350px;
            width: 95px;

            .item {
                width: 95px;
                height: 70px;
            }
        }

        &__slider {
            width: 602px;
        }
    }
}


@media(max-width: 992px) {
    .detail-info {
        padding: 60px 0;

        &__item {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:nth-child(even) {
                .detail-info {
                    &__info {
                        padding: 0;
                        align-items: flex-start;
                    }
                }
            }
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 15px;
        }

        &__tech {
            width: 100%;
        }

        &__btn {
            margin-bottom: 0;
            padding-top: 15px;
        }

        &__slider {
            order: 2;
            width: 100%;
            
        }

        &__info {
            order: 1;
            width: 100%;
            padding: 0;
            margin-bottom: 25px;
        }

        &__price {
            margin-bottom: 0;
        }

        .slider-for {
            width: 100%;
            height: 400px;
            float: inherit;
            margin-bottom: 5px;

            .item {
                width: 100%;
                height: 400px;
            }
        }


        .slider-nav {
            width: 100%;
            height: auto;
            float: inherit;

            .item {
                margin: 0 2px;
            }

            .slick-track {
                width: 100%;
                transform: translate3d(0, 0, 0) !important;
            }

            .slick-list {
                height: auto!important;
                width: 100%;
                display: block;
               
            }
        }
    }
}

@media(max-width: 768px) {
    .detail-info {
        .slider-for {
            height: 300px;

            .item {
                height: 300px;
            }
        }

        .slider-nav {
            .item {
                height: 44px;
            }

            .slick-list {
                padding: 0px 15px !important;
            }
        }

        &__title {
            font-size: 21px;
            margin-bottom: 6px;
        }

        &__tech {

            span {
                &:first-child {
                    font-size: 14px;
                }

                &:last-child {
                    font-size: 14px;
                }
            }
        }

        &__info {
            margin-bottom: 40px;
        }

        &__price {
            font-size: 22px;

            span {
                font-size: 38px;
            }
        }

        &__btn {
            padding-top: 4px;
        }
    }
}

@media(max-width: 560px) {
    .detail-info {
        padding: 40px 0 70px;

        .slider-for {
            height: 250px;

            .item {
                height: 250px;
            }
        }

        &__info {
            margin-bottom: 25px;
        }

        &__bottom {
            flex-direction: column;
        }

        &__item {
            margin-bottom: 55px;
        }


    }
}