.news {

    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    &__item {
        width: calc(~"33.3% - 40px");
        margin: 0 20px;
        position: relative;

        &:hover {
            .news {
                &__name {
                    color: @blue;
                }

                &__block {
                    margin-top: -15px;
                }
            }
        }


        &::before {
            content:" ";
            right: -18px;
            
            bottom: -30px;
            display: block;
            position: absolute;
            width: calc(~"100% - 12px");
            height: 100%;
            background: #000000;
            opacity: 0.1;
        }

    }

    &__name {
        font-family: "Geometric Sans Serif";
        font-size: 24px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 14px;
        transition: color 0.3s ease;
    }

    &__body {
        padding: 24px 30px 37px;
        display: flex;
        flex-direction: column;
        height: 375px;
        

        &-main {
            flex: 1 1 0;
        }
    }

    &__block {
        display: block;
        position: relative;
        text-decoration: none;
        background-color: #fff;
        min-height: 605px;
        transition: margin 0.3s ease;
    }

    &__text {
        font-family: "Rubik";
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.6); 
        margin-bottom: 40px;
    }

    &__img {
        height: 230px;
        background-position: center;
        background-size: cover;
        position: relative;

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            background-color: #000;
        }
    }

    &__date {
        font-family: "Rubik";
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0,0,0,.6);
        margin-bottom: 5px;
    }

    &__more {
        font-family: "Geometric Sans Serif";
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.11em;
        text-transform: uppercase;
        color: #1CAFED;
    }
}


@media(max-width: 1200px) {
    .news {
        &__img {
            height: 175px;  
        }

        &__block {
            min-height: 550px;
        }

        &__text {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
        }

        &__name {
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 10px;
        }

        &__item {
            &::before {
                bottom: -18px;
            }
        }

        &__date {
            font-size: 14px;
            line-height: 17px;
        }

        &__body {
            padding: 20px;
            height: auto;
        }
    }
}

@media (max-width: 992px) {
    .news {
        margin: 0;

        &__more {
            font-size: 12px;
            line-height: 16px;
        }

        &__text {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
            height: 60px;
            overflow: hidden;;
        }

        &__img {
            width: 250px;
            flex: none;
        }

        &__name {
            width: 100%;
            font-size: 20px;
            line-height: 22px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__body {
            padding: 15px;
            display: block;
            width: calc(~"100% - 250px");
        }

        &__block {
            min-height: initial;
            display: flex;
        }

        &__item {
            width: 100%;
            margin: 0;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                right: -10px;
                bottom: -10px;
            }
        }
    }
}

@media(max-width: 560px) {
    .news {
        &__img {
            display: none;
        }

        &__item {
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
            margin-bottom: 20px;
            
            &::before {
                display: none;
            }
        }

        &__body {
            width: 100%;
        }
    }
}