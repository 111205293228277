.detail-banner {
    height: 621px;
    position: relative;
    flex-direction: column;
    padding: 90px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;

    &::before {
        content:" ";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background: #000000;
        opacity: 0.5;
    }


    &__title {
        position: relative;
        font-family: "Geometric Sans Serif";
        font-size: 80px;
        line-height: 99px;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        margin: 0;
        margin-bottom: 49px;

        span {
            position: relative;

            &::before {
                content: " ";
                position: absolute;
                bottom: -3px;
                left: -2.5%;
                right: 0;
                width: 105%;
                background-color: #fef132;
                height: 20px;
            }
        }

    }

    &__text {
        position: relative;
        max-width: 848px;
        margin: 0 auto;
        font-family: Rubik;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center; 
        color: #FFFFFF;        
    }
}

@media(max-width: 1310px) {
    .detail-banner {
        height: 510px;
        padding: 60px 0 0;

        &__title {
            font-size: 66px;
            line-height: 1.2;

            span {
                &::before {
                    height: 14px;
                    bottom: -3px;
                }
            }
        }

        &__text {
            font-size: 18px;
            line-height: 1.4;
        }
    }
    
}


@media(max-width: 992px) {
    .detail-banner {
        padding: 60px 15px 15px 15px;


        &__title {
            font-size: 56px;
            line-height: 1.2;

            span {
                &::before {
                    bottom: -5px;
                }
            }
        }
        
        &__text {
            max-width: 100%;

        }
    }
}


@media(max-width: 560px) {
    .detail-banner {
        padding: 93px 15px 60px;
        height: auto;

        
        &__title {
            font-size: 35px;
            margin-bottom: 25px;

            span {
                &::before {
                    bottom: -4px;
                    height: 8px;      
                }
            }
        }

        &__text {
            font-size: 15px;
        }
    }
}